.rollover-card {
    &.bg-blue-1 {
        @screen md {
            background: url('/static/images/rollover-card-bg--blue-1.svg') no-repeat;
            background-size: 100% 100%;
        }
    }
    &.bg-green-2 {
        @screen md {
            background: url('/static/images/rollover-card-bg--green-2.svg') no-repeat;
            background-size: 100% 100%;
        }
    }
    &.bg-green-3 {
         @screen md {
             background: url('/static/images/rollover-card-bg--green-3.svg') no-repeat;
             background-size: 100% 100%;
         }
    }
    &.bg-green-4 {
        @screen md {
            background: url('/static/images/rollover-card-bg--green-4.svg') no-repeat;
            background-size: 100% 100%;
        }
    }
    &.bg-purple-1 {
        @screen md {
            background: url('/static/images/rollover-card-bg--purple-1.svg') no-repeat;
            background-size: 100% 100%;
        }
    }
    &.bg-purple-2 {
         @screen md {
             background: url('/static/images/rollover-card-bg--purple-2.svg') no-repeat;
             background-size: 100% 100%;
         }
    }
    &:hover {
        > div {
            @apply bottom-0;
        }
        @screen md {
            svg {
                transform: rotate(135deg) #{!important};
            }
        }
    }
    @apply hover:cursor-pointer;

    &.open {
        svg {
            transform: rotate(90deg);
        }
    }
}
