// -----------------------------------------------------------------------------
// MOBILE MENU
// -----------------------------------------------------------------------------

.mobile-nav--panel-1-active, .mobile-nav--panel-2-active, .mobile-nav--panel-3-active {
    @apply transition-none;
}

html.mobile-nav--visible,
html.mobile-nav--visible body {
    @apply overflow-hidden;
    @apply h-full;
    @apply fixed;
    @apply w-full;

    .mobile-nav {
        @apply opacity-100 #{!important};
        @apply translate-x-0 #{!important};
        @apply z-100 #{!important};
        @apply bg-green-1 #{!important};
    }
}

html.mobile-nav--visible {
    // burger icon
    div[data-mobile-nav-toggle] {
        @apply hidden;
    }
    // close menu link
    a[data-mobile-nav-toggle] {
        @apply block #{!important};
    }
}

// -----------------------------------------------------------------------------
// Panels
// -----------------------------------------------------------------------------

.mobile-nav__panel-container {

     //to add more panels just follow the below pattern
    .mobile-nav--panel-2-active & {
        transform: translate3d(-25%, 0, 0);
    }

    .mobile-nav--panel-3-active & {
        transform: translate3d(-50%, 0, 0);
    }
}
