/**
 * Content wrappers set the second order layout properties for site content.
 * Specifically they set the number of grid columns that content should take up,
 * the vertical padding between content, and the horizontal alignment of content.
 *
 * Note: As content wrappers are a second order layout the maximum horizontal space
 *       space they can take up is confined by the wrapping first order layout (container).
 */


// Widths
// ------

.content-wrapper-width--narrow {
    @apply w-full;
    @screen md { @apply w-7/12; }
}

.content-wrapper-width--wide {
    @apply w-full;
    @screen md { @apply w-10/12; }
}

.content-wrapper-width--full { @apply w-full;}


// Alignment
// ---------

// Note: Alignments can be width context dependent

.content-wrapper-align--left { margin-right: auto; }
.content-wrapper-align--center { margin-left: auto; margin-right: auto; }
.content-wrapper-align--right { margin-left: auto; }


// Vertical spacing
// ----------------

.content-wrapper-vspace--small {
    @apply pb-5;
    @screen md { @apply pb-5; }
}

.content-wrapper-vspace--medium {
    @apply py-5;
    @screen md { @apply py-10; }
}

.content-wrapper-vspace--large {
    @apply py-6;
    @screen md { @apply py-12; }
}
