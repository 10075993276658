// hides scrollbars in supported browsers while maintaining scroll capability
@mixin invisible-scrollbars {
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }
}

.invisible-scrollbars {
    @include invisible-scrollbars();
}
