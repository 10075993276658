.block--headline {
  .bg-green-1 {
    p {
      @apply text-blue-1;
    }
  }
  .highlight-green-4 {
    .highlight-text {
        @apply bg-green-4;
      }
  }
  .highlight-blue-1 {
    .highlight-text {
        @apply bg-blue-1;
      }
  }
  .highlight-purple-2 {
    .highlight-text {
        @apply bg-purple-2;
      }
  }
}


