// Note: You should not need to edit this file for any reason. If you need
// to add custom css add it to 1_base / 2_components / 3_utilities.

// Framework: Base generic framework styles. DO NOT EDIT THESE.
@import '0_framework/index';

// Base: Project specific base styles
@import '1_base/index';

// Components: Project specific component styles
@import '2_components/index';

// Components: Project specific utilities
@import '3_utilities/index';

// biggerpicture.js
@import 'bigger-picture/css';
.bp-lock {
    overflow-y: initial;
}
