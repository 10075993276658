.gridline-color-blue-1 {
  [data-gridlines] {
    @apply border-blue-1;
  }
}

.gridline-color-green-3 {
  [data-gridlines] {
    @apply border-green-3;
  }
}

.gridline-color-purple-2 {
  [data-gridlines] {
    @apply border-purple-2;
  }
}

[data-slider-btn='left']:hover, [data-glide-dir='<']:hover {
  svg {
    @apply -translate-x-0.5;
  }
}
