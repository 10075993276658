/*
* Base button styles
*/

// Make a button look and behave like a link with:
// .btn--link { /* See 0_framework/1_base/links.scss  */}

.btn {
    @apply inline-block;
    @apply px-7;
    @apply py-5;

    @apply border-2;
    @apply rounded-full;

    @apply text-center;
    @apply font-bold;
    @apply align-middle;
    @apply select-none;
    @apply whitespace-nowrap;
    @apply no-underline;

    @apply cursor-pointer;

    .disabled,
    &:disabled {
        @apply opacity-50;
        @apply shadow-none;
        @apply pointer-events-none;
    }

    @apply transition;
}
