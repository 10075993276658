.rich-text {
  ul {
    @apply list-disc;
    @apply ml-6;
  }
  ol {
    @apply list-decimal;
    @apply ml-6;
  }
  ol > li > ol {
    @apply list-alpha
  }
  ul > li > ul {
    @apply list-circle
  }
  p, ul, ol {
    @apply md:text-xl;
    @apply sm+:text-lg;
    @apply text-base;
    @apply mb-2;
  }
  li {
    @apply mb-2;
  }
  h2, h3, h4, h5 {
    @apply mb-4;
  }
}

.highlight-text {
  @apply bg-green-3;
  @apply text-green-1;
  @apply p-1;
  @apply rounded-md;
   @screen md+ {
    @apply p-2;
  }
}
