// This overrides the glide.js plugin styling to allow us to overflow the right-hand side
// of the slider out of the container and off the viewport

body {
  overflow-x: hidden;
}

.glide__track {
  overflow: visible !important;
  //clip-path: inset( -100vw -100vw -100vw -5px );
}

.glide--disabled .glide__arrows {
  @apply hidden;
}

.glide__slide--text {
  @apply rounded-2xl;
  p, h3 {
    @apply text-green-1;
  }

  $count: 1;
  @for $i from 1 through 8 {
    @if $i % 4 == 1 {
      &:nth-of-type(#{$i}) {
        @apply bg-green-2;
        .glide__slide--text__inner {
           background: url('/static/images/text-slide-inner-green.svg') no-repeat;
           background-size: 100% 100%;
          .glide__slide--text__content {
            p {
              @apply absolute;
              @apply bottom-[1rem];
              @apply pr-4;
              @screen md {
                @apply bottom-[2rem];
              }
            }
            h3 {
              @apply max-w-[74%];
            }
          }
        }
      }
    }
    @else if $i % 4 == 2 {
      &:nth-of-type(#{$i}) {
        @apply bg-green-1;
        .glide__slide--text__inner {
          background: url('/static/images/text-slide-inner-purple.svg') no-repeat;
          background-size: 100% 100%;
          .glide__slide--text__content {
            @apply flex-col-reverse;
            .glide__slide--text__content__text {
              p {
                  @apply pr-4;
                  @apply text-green-1;
              }
            }
            h3 {
              @apply absolute;
              @apply bottom-[1rem];
              @apply left-[27%];
              @apply pr-4;
              @apply text-green-1;
              @screen md {
                @apply bottom-[2rem];
              }
            }
          }
        }
        .glide__slide--text__extra-bg {
          @apply bg-purple-2;
          @apply w-full;
          @apply h-1/2;
          @apply top-4;
          @apply rounded-t-2xl;
        }
      }
    }
    @else if $i % 4 == 3 {
      &:nth-of-type(#{$i}) {
        @apply bg-purple-1;
        .glide__slide--text__inner {
          background: url('/static/images/text-slide-inner-blue.svg') no-repeat;
          background-size: 100% 100%;
          .glide__slide--text__content {
            p {
              @apply absolute;
              @apply bottom-[1rem];
              @apply pr-4;
              @screen md {
                @apply bottom-[2rem];
              }
            }
            h3 {
              @apply max-w-[74%];
            }
          }
        }
        .glide__slide--text__extra-bg {
          @apply bg-blue-1;
          @apply w-full;
          @apply h-1/2;
          @apply bottom-4;
          @apply rounded-b-2xl;
        }
      }
    }
    @else {
      &:nth-of-type(#{$i}) {
        @apply bg-green-4;
        .glide__slide--text__inner {
          background: url('/static/images/text-slide-inner-grey.svg') no-repeat;
          background-size: 100% 100%;
          .glide__slide--text__content {
            @apply flex-col-reverse;
            .glide__slide--text__content__text {
              p {
                  @apply pr-4;
              }
            }
            h3 {
              @apply absolute;
              @apply bottom-[1rem];
              @apply left-[27%];
              @apply pr-4;
              @screen md {
                @apply bottom-[2rem];
              }
            }
          }
        }
        .glide__slide--text__extra-bg {
          @apply bg-grey-1;
          @apply w-full;
          @apply h-1/2;
          @apply top-4;
          @apply rounded-t-2xl;
        }
      }
    }
  }
}
