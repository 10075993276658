.btn {
  &:hover {
    @apply no-underline;
    svg {
      @apply translate-x-0.5;
    }
  }
  &.btn--transparent {
    @apply border-current;
    @apply bg-transparent;
    &:hover {
      @apply bg-white-1;
      @apply text-green-1;
    }
  }
  &.btn--transparent--dark-text {
    @apply border-green-1;
    @apply text-green-1;
    &:hover {
      @apply bg-green-1;
      @apply text-white-1;
    }
  }
  &.btn--transparent--dark-text--white-bg {
    @apply text-green-1;
    &:hover {
      @apply bg-white-1;
    }
  }
  &.btn--green-1 {
    @apply text-white-1;
    @apply bg-green-1;
    @apply border-green-1;
    &:hover {
      @apply bg-white-1;
      @apply text-green-1;
    }
  }
  &.btn--green-4 {
    @apply bg-green-4;
    @apply border-green-4;
    &:hover {
      @apply bg-white-1;
    }
  }
  &.btn--blue-1 {
    @apply border-blue-1;
    @apply bg-blue-1;
    &:hover {
      @apply border-blue-1;
      @apply bg-green-1;
      @apply text-white-1;
    }
  }
}
