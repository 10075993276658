[data-scrollreveal-headline],
[data-scrollreveal-slider-text] .glide__slide--text,
[data-scrollreveal-slider-image] .glide__slide--image,
[data-scrollreveal-feature-img-text] > div,
[data-scrollreveal-rollover] .rollover-card,
[data-scrollreveal-vimeo] > div,
[data-scrollreveal-block-header] h2,
[data-scrollreveal-block-header] p,
[data-scrollreveal-block-header] a,
[data-scrollreveal-people-slider] .glide__slide--person-wrapper,
[data-scrollreveal-sponsors] > a.sponsor-item,
{
    @screen md {
         @apply invisible;
    }
}
