@tailwind base;

@layer base {
    @import '1_base/links';
    @import '1_base/spacing';
    @import '1_base/typography';
    @import '1_base/font-face';

    :root {
        --site-width: 1440px;
    }
}

@tailwind components;

@layer components {
    @import '2_components/buttons';
    @import '2_components/containers';
    @import '2_components/collapsible';
    @import '2_components/parallax';
    @import '2_components/responsive-object';
}

@tailwind utilities;

@layer utilities {
    @import '3_utilities/mixins';
}
