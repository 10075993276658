.template-articlepage {
    .rich-text {
      h2:not(.h1) {
        @apply text-2xl;
      }
      h3, h4, h5 {
        @apply font-normal;
      }
      p, ul {
        @apply text-base;
      }
    }
}
