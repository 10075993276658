.block--video {
  .bg-green-1 {
    * {
      @apply text-white-1;
    }
  }
}

#bp_container .bp-x, .bp-wrap .bp-x {
  border-radius: 0 #{!important};
  &:hover {
    background: none #{!important};
  }
}
