:root {
    --header-height: 64px;
}

@screen md+ {
  :root {
    --header-height: 78px;
  }
}

header {
  height: var(--header-height);
}

.header-height {
  height: var(--header-height);
}

.-header-over-banner {
  header {
    @apply absolute;
  }
}

.-header-reversed #header-pill {
  @apply bg-blue-1 #{!important};
  a {
    @apply text-green-1 #{!important};
  }
}

.header-link .active {
  @apply bg-opacity-10;
}

.header-link div:first-child:hover,
.header-link div:first-child:focus {
  @apply bg-opacity-10;
}

header {
  nav {
    ul li a {
      @apply font-bold;
      @apply text-lg;
      @apply no-underline;
      @apply p-4;
      @apply bg-transparent;
      @apply border-[1px];
      @apply border-transparent;
      @apply rounded-full;
      @apply mr-4;
      &:hover {
        @apply bg-grey-1;
        @apply border-grey-1;
        @apply text-green-1;
        @apply no-underline;
      }
    }
    .active a {
       @apply bg-green-3;
       @apply border-green-3;
       @apply text-green-1;
       @apply p-4;
       @apply rounded-full;

        &[data-highlight-color='green-3'] {
          @apply bg-green-3;
          @apply border-green-3;
          @apply text-green-1;
        }

        &[data-highlight-color='blue-1'] {
            @apply bg-blue-1;
            @apply border-blue-1;
            @apply text-green-1;
        }

        &[data-highlight-color='purple-2'] {
            @apply bg-purple-2;
            @apply border-purple-2;
            @apply text-green-1;
        }
    }
  }
}

a[data-highlight-color='green-3'] {
  &:hover {
    @apply bg-green-3;
    @apply border-green-3;
    @apply text-green-1;
  }
}

a[data-highlight-color='blue-1'] {
  &:hover {
    @apply bg-blue-1;
    @apply border-blue-1;
    @apply text-green-1;
  }
}

a[data-highlight-color='purple-2'] {
  &:hover {
    @apply bg-purple-2;
    @apply border-purple-2;
    @apply text-green-1;
  }
}
